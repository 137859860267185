import React from 'react';

import * as styles from '../../css/appPages/Help.module.scss';

import Layout from '../../components/app/layout/Layout';

import team from '../../images/app/ripplesTeam.png';
import email from '../../images/app/email.png';
import phone from '../../images/app/phone.png';

const Help = () => {
    return (
        <Layout mainClass={styles.Help}>
            <section className={styles.intro}>
                <h1>Contact us</h1>
                <p>
                    We're here to help. Contact us if you have any questions or
                    feedback.{' '}
                </p>
            </section>
            <section className={styles.contact}>
                <ul>
                    <li>
                        <a href="mailto:hello@joinripples.org">
                            <img
                                src={email}
                                alt="Email icon"
                                width="25"
                                height="25"
                            />
                            <p>hello@joinripples.org</p>
                        </a>
                    </li>
                    <li>
                        <a href="tel:02080641259">
                            <img
                                src={phone}
                                alt="Phone icon"
                                width="25"
                                height="25"
                            />
                            <p>0208 0641 259</p>
                        </a>
                    </li>
                    <li>Live chat or leave us a message</li>
                </ul>
            </section>
            <section>
                <img src={team} alt="Ripples team" />
            </section>
        </Layout>
    );
};

export default Help;
