import React from 'react';
import classnames from 'classnames';

import logo from '../../../images/nav/ripples-logo.png';

import * as styles from './Layout.module.scss';

const Layout = ({ children, mainClass }) => {
    return (
        <>
            <div className={styles.img}>
                <img src={logo} alt="Ripples logo" width="113" height="40" />
            </div>
            <main className={classnames(styles.Layout, mainClass)}>
                {children}
            </main>
        </>
    );
};

export default Layout;
